<script>
export default {
  name: 'ReactiveAccount',
  computed: {
    workspaceId () {
      return this.workspace.id
    },
    workspace () {
      return this.$store.getters.getIndividualWorkspace
    },
    isUserDeleted () {
      return this.workspace && this.workspace.deletionRequest && this.workspace.deletionRequest.status !== 'CANCELED'
    }
  },
  methods: {
    reactiveUser () {
      this.$store.dispatch('attemptReactivateWorkspace', this.workspaceId).then(() => {
        this.$store.dispatch('attemptGetUserWorkspace', 'individual').then(() => {
          this.$router.push({ name: this.selectedWorkspace.type + '.home.user' })
          this.$store.commit('setSelectedWorkspace', this.$store.getters.getUserWorkspaces.find(workspace => {
            return workspace.id === this.$store.getters.getIndividualWorkspace.id
          }))
        })
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'success',
          title: this.$t('user.reactivate:feedback.success')
        })
      })
    }
  },
  created () {
    if (!this.isUserDeleted) {
      this.$router.push({ name: this.selectedWorkspace.type + '.home.user' })
    }
  }
}
</script>
<template>
  <section class="reactivate-container" v-if="isUserDeleted">
    <div class="reactivate-wrapper">
      <div class="reactivate-header">
        <h2>{{ $t('reactivate.account:disabled.header.1') }}</h2>
        <h5>{{ $t('reactivate.account:disabled.header.2') }}</h5>
      </div>
      <v-card class="reactivate-body">
        <p v-html="$t('reactivate.account:disabled.paragraph.1', { name: $store.getters.getUser.name.split(' ')[0] })"></p>
        <p v-html="$t('reactivate.account:disabled.paragraph.2', { date: formatDate(workspace.deletionRequest.createdAt) })"></p>
        <p v-html="$t('reactivate.account:disabled.paragraph.3')"></p>
        <p v-html="$t('reactivate.account:disabled.paragraph.4')"></p>
        <p v-html="$t('reactivate.account:disabled.paragraph.5')"></p>
        <v-btn
          class="bold reactivate-button"
          color="#1200D3"
          dark
          @click="reactiveUser"
        >
          {{ $t('reactivate.account:reactivate.button') }}
        </v-btn>
      </v-card>
    </div>
  </section>
</template>
<style lang="scss">
.reactivate-container {
  display: block;
  min-height: 100vh;
  padding-top: 70px;
  background: linear-gradient(to bottom, $primary-dark, $primary-darkest-alt);
  .reactivate-wrapper {
    width: 620px;
    max-width: 100%;
    margin: 70px auto 0;
  }
  .reactivate-header {
    color: $neutral-white;
    margin-bottom: 70px;
    text-align: left;
    font-family: $lato;
    h2 {
      font-size: $font-size-large
    }
    h5 {
      font-size: $font-size-normal;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 25px;
    }
  }
  .reactivate-body {
    padding: 30px 25px 40px;
    text-align: left;
    font-family: $lato;
    p {
      color: $neutral-black;
      line-height: $line-height-medium;
    }
    .reactivate-button {
      margin-top: 10px;
    }
  }
}
</style>
